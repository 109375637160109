import React, { useMemo } from 'react';
import { VictoryBar, VictoryTooltip, VictoryChart, VictoryAxis, } from 'victory';
import { COLORS } from '@humanfirst/elektron';
import { GRADIENTS, GradientDefinitions } from './GradientDefinitions';
import { GRAPH_THEME } from './theme';
// Width of the entinre svg.
const CHART_WIDTH = 900;
// Padding of the svg. This allows the space we need for labels.
const CHART_PADDING = 40;
// Domain padding gives the bars space from the axis.
const CHART_DOMAIN_PADDING = { x: 40, y: 0 };
// Maximum length (in characters) of an x label. Items longer than this
// will be truncated.
const MAX_X_LABEL_LENGTH = 25;
// Define events on our data. For hover, we show the tooltip and update the style
const DATA_EVENTS = [
    {
        target: 'data',
        eventHandlers: {
            onMouseOver: () => {
                return [
                    {
                        target: 'data',
                        mutation: ({ style }) => ({
                            style: Object.assign(Object.assign({}, style), { fillOpacity: 0.5, strokeWidth: 1, stroke: COLORS.primary, cursor: 'pointer' }),
                        }),
                    },
                    {
                        target: 'labels',
                        mutation: () => ({ active: true }),
                    },
                ];
            },
            onMouseOut: () => {
                return [
                    {
                        target: 'data',
                        mutation: () => ({}),
                    },
                    {
                        target: 'labels',
                        mutation: () => ({ active: false }),
                    },
                ];
            },
        },
    },
];
/**
 *
 * The bar chart will display a single bar for up to  5 items on the x axis.
 * EX:
 *
 * 10 - |               _           _
 *      |         _    | |         | |
 *      |        | |   | |         | |
 * 5 -  |   _    | |   | |         | |
 *      |  | |   | |   | |    _    | |
 *      |  | |   | |   | |   | |   | |
 *      |__|_|___|_|___|_|___|_|___|_|_
 *          |     |     |     |     |
 *          1     2     3     4     5
 */
const SingleBarChart = ({ data, variant = 'functional', }) => {
    const yDomainMax = useMemo(() => {
        const maxFromData = data.reduce((acc, d) => (d.y > acc ? d.y : acc), 0);
        const interval = maxFromData < 15 ? 2 : maxFromData < 40 ? 5 : 10;
        const rem = maxFromData % interval;
        if (rem) {
            return maxFromData + (interval - rem);
        }
        return maxFromData;
    }, [data]);
    return (React.createElement(VictoryChart, { width: CHART_WIDTH, padding: CHART_PADDING, domainPadding: CHART_DOMAIN_PADDING, theme: GRAPH_THEME, 
        // 4 is the minimum number for keeping whole numbers on they axis.
        domain: { y: [0, yDomainMax < 4 ? 4 : yDomainMax] } },
        React.createElement(VictoryBar, { style: {
                data: {
                    fill: ({ index }) => {
                        // For functional, keep all bars one color.
                        if (variant === 'functional') {
                            return `url(#${GRADIENTS[0].id})`;
                        }
                        return `url(#${GRADIENTS[Number(index) % GRADIENTS.length].id})`;
                    },
                },
            }, data: data, labels: ({ datum }) => `${datum.x}: ${datum.y}`, labelComponent: React.createElement(VictoryTooltip, null), events: DATA_EVENTS }),
        React.createElement(VictoryAxis, { tickFormat: (x) => x.length > MAX_X_LABEL_LENGTH
                ? x.slice(0, MAX_X_LABEL_LENGTH) + '...'
                : x }),
        React.createElement(VictoryAxis, { tickFormat: (y) => Math.round(y), dependentAxis: true }),
        React.createElement(GradientDefinitions, null)));
};
export { SingleBarChart };
