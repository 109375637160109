import { EvidenceResource, MeasureCategoryResource, OrganizationResource, ToolResource, MedicalConditionsResource, StudyResource, } from '@humanfirst/use-elektra-api';
import React from 'react';
import { OrganizationTableSimple } from 'src/components/OrganizationTableSimple/OrganizationTableSimple';
import { SimpleToolTable } from 'src/components/tables/ToolTable';
import { PATHS } from 'src/config/path';
import { deserializeQuery } from 'src/utils/query';
import { MedicalConditionTableSimple } from 'src/components/tables/MedicalConditionTable';
import { EvidenceTableSimple } from 'src/components/tables/EvidenceTable';
import { StudyTableSimple } from 'src/components/tables/StudyTable';
import { MeasureTableSimple } from './components/MeasureTableSimple';
import { SearchTable } from './components/SearchTable/SearchTable';
const STARRED_SORT_ORDER_INITIAL_STATE = {
    sort: {
        column: 'starred',
        ascending: false,
    },
};
const TechnologyTableWrapper = ({ data, extraColumns, extraActions, }) => {
    return (React.createElement(SimpleToolTable, { initialState: STARRED_SORT_ORDER_INITIAL_STATE, data: data, extraColumns: extraColumns, extraActions: extraActions }));
};
const VendorTableWrapper = ({ data, extraColumns, extraActions, }) => {
    return (React.createElement(OrganizationTableSimple, { initialState: STARRED_SORT_ORDER_INITIAL_STATE, data: data, extraColumns: extraColumns, extraActions: extraActions }));
};
const EvidenceTableWrapper = ({ data: evidence, extraColumns, extraActions, }) => {
    return (React.createElement(EvidenceTableSimple, { data: evidence, extraColumns: extraColumns, initialState: STARRED_SORT_ORDER_INITIAL_STATE, extraActions: extraActions }));
};
const MeasureTableWrapper = ({ data: measures, extraColumns, extraActions, }) => {
    return (React.createElement(MeasureTableSimple, { measures: measures, extraColumns: extraColumns, initialState: STARRED_SORT_ORDER_INITIAL_STATE, extraActions: extraActions }));
};
const SearchTableWrapper = ({ data, extraColumns, extraActions, }) => {
    return (React.createElement(SearchTable, { data: data, extraColumns: extraColumns, initialState: STARRED_SORT_ORDER_INITIAL_STATE, extraActions: extraActions }));
};
const MedicalConditionTableWrapper = ({ data: medicalConditions, extraColumns, extraActions, }) => {
    return (React.createElement(MedicalConditionTableSimple, { data: medicalConditions, extraColumns: extraColumns, initialState: STARRED_SORT_ORDER_INITIAL_STATE, extraActions: extraActions }));
};
const StudyTableWrapper = ({ data, extraColumns, extraActions, }) => {
    return (React.createElement(StudyTableSimple, { initialState: STARRED_SORT_ORDER_INITIAL_STATE, extraColumns: extraColumns, extraActions: extraActions, data: data }));
};
const SearchSection = {
    type: 'mapped',
    resourceType: ['ToolSearch', 'VendorSearch', 'MeasureSearch'],
    resourceMapping: (bookmarks) => bookmarks.map((x) => ({
        id: x.id,
        resourceType: x.resourceType,
        query: deserializeQuery(x.resource),
        __bookmark: x,
    })),
    title: 'Searches',
    display: SearchTableWrapper,
};
const TechnologySection = {
    type: 'api',
    resourceType: 'Tool',
    resourceEndpoint: (bookmarks) => ToolResource.getBulk(bookmarks.map((x) => x.resource), { join: ['medicalConditions', 'maker'] }),
    display: TechnologyTableWrapper,
    title: 'Technologies',
    atlasLink: PATHS.atlas.technologies.list,
};
const VendorsSection = {
    type: 'api',
    resourceType: 'Vendor',
    resourceEndpoint: (bookmarks) => OrganizationResource.getBulk(bookmarks.map((x) => x.resource), { join: ['components'] }),
    display: VendorTableWrapper,
    title: 'Vendors',
    atlasLink: PATHS.atlas.vendors.list,
};
const EvidenceSection = {
    type: 'api',
    resourceType: 'EvidenceSource',
    resourceEndpoint: (bookmarks) => EvidenceResource.getBulk(bookmarks.map((x) => x.resource), {
        join: [
            'sourceMeasures||id,measureCategories',
            'sourceMeasures.tools||id,model,imageSubmittedCDN,imageSubmittedExists',
            'medicalConditions||id,name',
            'therapeuticArea||id,therapeuticArea',
        ],
    }),
    display: EvidenceTableWrapper,
    title: 'Evidence',
};
const MeasuresSection = {
    type: 'api',
    resourceType: 'Measure',
    resourceEndpoint: (bookmarks) => MeasureCategoryResource.getBulk(bookmarks.map((x) => x.resource)),
    display: MeasureTableWrapper,
    title: 'Measures',
    atlasLink: PATHS.atlas.measures.list,
};
const MedicalConditionsSection = {
    type: 'api',
    resourceType: 'MedicalCondition',
    resourceEndpoint: (bookmarks) => MedicalConditionsResource.getBulk(bookmarks.map((x) => x.resource)),
    display: MedicalConditionTableWrapper,
    title: 'Medical Conditions',
    atlasLink: PATHS.atlas.medicalConditions.list,
};
export const StudiesSection = {
    type: 'api',
    resourceType: 'Study',
    resourceEndpoint: (bookmarks) => StudyResource.getBulk(bookmarks.map((x) => x.resource)),
    display: StudyTableWrapper,
    title: 'Studies',
    atlasLink: PATHS.catalog.studies.index,
};
const SECTIONS = [
    SearchSection,
    MedicalConditionsSection,
    MeasuresSection,
    TechnologySection,
    VendorsSection,
    EvidenceSection,
];
const SECTIONS_WITH_STUDIES = [
    SearchSection,
    MedicalConditionsSection,
    MeasuresSection,
    TechnologySection,
    VendorsSection,
    EvidenceSection,
    StudiesSection,
];
export { SECTIONS, SECTIONS_WITH_STUDIES };
