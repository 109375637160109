import React from 'react';
import { Block, Card, COLORS, Divider, Heading2, Stack, Typography, TypographyVariant, } from '@humanfirst/elektron';
import { When } from 'react-if';
import { useDedupeTechnologyForEvidence } from 'src/hooks/evidence';
import { getMeasureCategoryByTechnology } from 'src/utils/evidence';
import { TechAndMeasures } from 'src/components/Evidence/EvidenceTechWithMeasures';
const MeasuresByTech = ({ item }) => {
    var _a;
    const technologies = useDedupeTechnologyForEvidence(item);
    const measuresByTechnology = getMeasureCategoryByTechnology(item);
    // Partially ingested may have tech tagged without measures. In this case we won't show
    // the measures column.
    const hasMeasures = [...measuresByTechnology.values()].some((x) => x.length > 0);
    const unspecifiedTechnology = measuresByTechnology.get('NOT FOUND');
    if (!((_a = item.sourceMeasures) === null || _a === void 0 ? void 0 : _a.length)) {
        return React.createElement(Typography, null, "No technologies or measures listed.");
    }
    return (React.createElement(Stack, { gap: "16px" },
        React.createElement(Stack, { direction: "horizontal" },
            React.createElement(Block, { width: "5%" }),
            React.createElement(Block, { flex: "1" },
                React.createElement(Typography, { variant: TypographyVariant.LabelBold, color: COLORS.gray1 }, "Technology")),
            hasMeasures ? (React.createElement(Block, { flex: "1" },
                React.createElement(Stack, { direction: "horizontal", alignItems: "center" },
                    React.createElement(Typography, { variant: TypographyVariant.LabelBold, color: COLORS.gray1 }, "Measures")))) : null),
        React.createElement(Divider, null),
        technologies.map((tech, i) => (React.createElement(Block, { key: `${item.id} ${tech.id}` },
            React.createElement(TechAndMeasures, { tool: tech, measures: measuresByTechnology.get(tech.id), hasMeasures: hasMeasures }),
            i < technologies.length - 1 ? React.createElement(Divider, null) : null))),
        React.createElement(When, { condition: !!(unspecifiedTechnology === null || unspecifiedTechnology === void 0 ? void 0 : unspecifiedTechnology.length) },
            React.createElement(TechAndMeasures, { measures: unspecifiedTechnology, hasMeasures: hasMeasures }))));
};
const MeasuresByTechCard = ({ evidence }) => {
    return (React.createElement(Card, null,
        React.createElement(Stack, { gap: "16px" },
            React.createElement(Heading2, { marginBottom: "0px", variant: TypographyVariant.Heading2Bold }, "Technologies"),
            React.createElement(MeasuresByTech, { item: evidence }))));
};
export { MeasuresByTechCard };
