import { __rest } from "tslib";
import { Block, Button, COLORS, Center, DataTable, Link, Stack, Typography, useDataTableState, } from '@humanfirst/elektron';
import React, { useMemo } from 'react';
import { useTrackDataTableRefinement } from 'src/hooks/analytics/track-table-refinement';
import { EmptyStateOtherOptions } from 'src/components/EmptyStateOtherOptions';
import { useToggle } from 'src/hooks/toggle';
import { ConnectOptionsButton } from 'src/components/Connect/ConnectOptionsButton';
import { PATHS } from 'src/config/path';
import { useShowModalBound } from 'src/hooks/modal';
import { MODAL_PATHS } from 'src/config/modals/path';
import { useCustomizedSchema } from '../hooks/use-customized-schema';
import { useDataTableUrlMemory } from '../hooks/use-data-table-url-memory';
import { useDataTableUrlAlgolia } from '../hooks/use-data-table-url-algolia';
import { useEvidenceFilters } from './filters';
import { useEvidenceColumns } from './columns';
import { SelectionOptions } from './components/SelectionOptions';
const DEFAULT_INITIAL_STATE = { sort: { column: 'title', ascending: true } };
const EMPTY_STATE_OTHER_OPTIONS = React.createElement(EmptyStateOtherOptions, null);
const ACTIONS = (React.createElement(Stack, { direction: 'horizontal' },
    React.createElement(SelectionOptions, null),
    React.createElement(ConnectOptionsButton, null)));
const MissingEvidence = () => {
    const showModalBound = useShowModalBound(MODAL_PATHS.requestEvidenceIngestion);
    return (React.createElement(Block, null,
        React.createElement(Center, null,
            React.createElement(Typography, { color: COLORS.gray1 },
                "Not seeing certain evidence?",
                ' ',
                React.createElement(Link, { href: PATHS.helpCenter.usingAtlas.measuresAndEvidence.evidenceIngestion }, "Check out ingestion criteria"),
                ' ',
                "or",
                ' ',
                React.createElement(Button, { onClick: showModalBound, variant: "link" }, "request ingestion"),
                "."))));
};
const POST_PAGINATION_ELEMENT = React.createElement(MissingEvidence, null);
const ALGOLIA_CONFIGURATION = {
    searchType: 'Evidence',
    getSortIndex: (state) => {
        var _a, _b;
        if (((_a = state.sort) === null || _a === void 0 ? void 0 : _a.column) === 'title') {
            return state.sort.ascending ? 'title-asc' : 'title-desc';
        }
        if (((_b = state.sort) === null || _b === void 0 ? void 0 : _b.column) === 'year') {
            if (state.sort.ascending) {
                return 'year-asc';
            }
            else {
                return 'year-desc';
            }
        }
        return undefined;
    },
};
const checkHasTechnology = (data) => data.some((x) => { var _a; return (_a = x.sourceMeasures) === null || _a === void 0 ? void 0 : _a.some((x) => x.tools && x.tools.length > 0); });
const useBaseSchema = (data) => {
    const hasTechnology = checkHasTechnology(data);
    const columns = useEvidenceColumns(hasTechnology);
    const filters = useEvidenceFilters(hasTechnology);
    const customColumns = useToggle('custom-columns');
    return useMemo(() => ({
        columns,
        filters,
        selectable: true,
        configurableColumns: customColumns,
    }), [columns, filters, customColumns]);
};
const useSimpleBaseSchema = (data) => {
    const hasTechnology = checkHasTechnology(data);
    const columns = useEvidenceColumns(hasTechnology);
    return useMemo(() => ({
        columns,
        searchable: false,
        selectable: true,
        configurableColumns: true,
    }), [columns]);
};
const useBaseAlgoliaSchema = () => {
    const columns = useEvidenceColumns(true);
    const filters = useEvidenceFilters(true);
    const customColumns = useToggle('custom-columns');
    return useMemo(() => ({
        columns: columns.map((x) => (Object.assign(Object.assign({}, x), { sortable: x.id === 'title' || x.id === 'year' }))),
        filters,
        selectable: true,
        configurableColumns: customColumns,
    }), [columns, filters, customColumns]);
};
export const EvidenceTable = (_a) => {
    var { data, initialState = DEFAULT_INITIAL_STATE, scope } = _a, props = __rest(_a, ["data", "initialState", "scope"]);
    const schema = useBaseSchema(data);
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableUrlMemory(finalSchema, data, initialState, scope);
    useTrackDataTableRefinement('evidence', result);
    return (React.createElement(DataTable, Object.assign({}, result, { itemType: "evidence", emptyElement: EMPTY_STATE_OTHER_OPTIONS, postSelectionActions: ACTIONS, postPaginationElement: POST_PAGINATION_ELEMENT })));
};
export const EvidenceTableSimple = (_a) => {
    var { data, initialState = DEFAULT_INITIAL_STATE, extraActions } = _a, props = __rest(_a, ["data", "initialState", "extraActions"]);
    const schema = useSimpleBaseSchema(data);
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableState(finalSchema, data, initialState);
    return React.createElement(DataTable, Object.assign({}, result, { postSelectionActions: extraActions }));
};
export const EvidenceTableScoped = (_a) => {
    var { initialState = DEFAULT_INITIAL_STATE, scope, extraActions } = _a, props = __rest(_a, ["initialState", "scope", "extraActions"]);
    const schema = useBaseAlgoliaSchema();
    const finalSchema = useCustomizedSchema(schema, props);
    const result = useDataTableUrlAlgolia(finalSchema, ALGOLIA_CONFIGURATION, initialState, scope);
    useTrackDataTableRefinement('evidence', result);
    return (React.createElement(DataTable, Object.assign({}, result, { itemType: "evidence", emptyElement: EMPTY_STATE_OTHER_OPTIONS, postSelectionActions: React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
            ACTIONS,
            extraActions), postPaginationElement: POST_PAGINATION_ELEMENT })));
};
