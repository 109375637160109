import { COLORS, Spread, Stack, RankedStack, Typography, TypographyVariant, Block, Icons, } from '@humanfirst/elektron';
import React from 'react';
import { getEvidenceTypeCounts } from 'src/utils/evidence';
import { getCTPhasesWithCountValuesFromEvidence } from 'src/utils/clinicalTrials';
import { useCTPhasesCounts, useEvidenceCounts, useV3Counts } from './hooks';
/** Background colors for ranked bars. */
const BACKGROUND_COLORS = [
    COLORS.primary,
    COLORS.secondary,
    COLORS.blue3,
    COLORS.blue4,
];
/** Font colors for ranked bars (to ensure readability on different backgrounds). */
const FONT_COLORS = [COLORS.white, COLORS.white, COLORS.black, COLORS.black];
/** Font colors to be used in the RankedStack when all values are empty. */
const EMPTY_BACKGROUND_COLORS = [
    COLORS.black,
    COLORS.gray1,
    COLORS.gray2,
    COLORS.gray3,
];
const getEvidenceCountValues = (evidence) => {
    const counts = getEvidenceTypeCounts(evidence);
    const keys = ['Papers', 'Clinical Trials', 'Other'];
    return keys.map((key) => ({
        label: key,
        count: counts[key],
    }));
};
const V3_KEYS = ['Verification', 'Analytical Validation', 'Clinical Validation'];
const getV3CountValues = (evidence) => {
    var _a;
    const counts = {
        'Analytical Validation': 0,
        'Clinical Validation': 0,
        Verification: 0,
    };
    for (const e of evidence) {
        for (const key of V3_KEYS) {
            if ((_a = e.v3) === null || _a === void 0 ? void 0 : _a.includes(key)) {
                counts[key] += 1;
            }
        }
    }
    return V3_KEYS.map((key) => ({
        label: key,
        count: counts[key],
    }));
};
/** Converts a rank into a CSS width string. */
const rankToSize = (rank, totalRanks) => {
    // If we only have 1 rank everything should be 100%
    if (totalRanks === 1) {
        return '100%';
    }
    // The minimum bar width is 50%. This will interpolate smoothly between 50 at rank 0 to
    // 100 at the maximum rank.
    const precentage = (rank / (totalRanks - 1)) * 50 + 50;
    return `${precentage.toFixed(0)}%`;
};
const EvidenceBar = ({ children, backgroundColor, width, color, }) => {
    return (React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: color },
        React.createElement(Spread, { backgroundColor: backgroundColor, width: width, borderRadius: "60px", height: "2em", paddingLeft: "16px", paddingRight: "16px" }, children)));
};
const SingleCount = ({ item }) => {
    return (React.createElement(Block, null,
        React.createElement(Typography, null,
            item.label,
            " ",
            React.createElement("strong", null, `(${item.count})`))));
};
const RankedEvidenceBarsInternal = ({ values, v3Counts, ctPhasesCounts, shouldShowLocked }) => {
    if (shouldShowLocked) {
        return (React.createElement(Stack, null, values.map(({ label }) => (React.createElement(EvidenceBar, { backgroundColor: COLORS.blue4, width: "100%", key: label },
            React.createElement("span", null, label),
            React.createElement(Block, { overrides: {
                    Block: {
                        style: {
                            opacity: '0.7',
                        },
                    },
                } },
                React.createElement(Icons.Misc.Lock, { color: COLORS.black, size: '24px', title: "Locked" })))))));
    }
    // This will return the unique counts in sorted order. To get the corresponding
    // rank you can do an indexOf on the `count` value. Items are ranked in ascending
    // order so index 0 has the lowest rank.
    const rankedCounts = [...new Set(values.map((x) => x.count))].sort((a, b) => a - b);
    return (React.createElement(Stack, null, values.map(({ label, count }, i) => {
        const index = i % 4;
        return (React.createElement(React.Fragment, { key: label },
            React.createElement(EvidenceBar, { color: FONT_COLORS[index], backgroundColor: BACKGROUND_COLORS[index], width: rankToSize(rankedCounts.indexOf(count) || 0, rankedCounts.length) },
                React.createElement("span", null, label),
                React.createElement("span", null, count)),
            label === 'Papers' ? (React.createElement(Stack, { direction: "horizontal" },
                React.createElement(Icons.Misc.CornerRight, { size: "20px", decorative: true }),
                React.createElement(Stack, { direction: "horizontal", gap: "16px" }, v3Counts.map((x) => (React.createElement(SingleCount, { key: x.label, item: x })))))) : null,
            label === 'Clinical Trials' && ctPhasesCounts ? (React.createElement(Stack, { direction: "horizontal" },
                React.createElement(Icons.Misc.CornerRight, { size: "20px", decorative: true }),
                React.createElement(Stack, { direction: "horizontal", gap: "16px" }, ctPhasesCounts.map((x) => (React.createElement(SingleCount, { key: x.label, item: x })))))) : null));
    })));
};
/** Shows evidence bars based on a scope. */
export const ScopedRankedEvidenceBars = ({ scope, shouldShowLocked }) => {
    const values = useEvidenceCounts(scope);
    const v3Counts = useV3Counts(scope);
    const ctPhasesCounts = useCTPhasesCounts(scope);
    return (React.createElement(RankedEvidenceBarsInternal, { values: values, v3Counts: v3Counts, ctPhasesCounts: ctPhasesCounts, shouldShowLocked: shouldShowLocked }));
};
/**
 * A collection of bars representing relative rank of input values.
 */
const RankedEvidenceBars = ({ evidence, shouldShowLocked }) => {
    const values = getEvidenceCountValues(evidence);
    const v3Counts = getV3CountValues(evidence);
    const ctPhasesCounts = getCTPhasesWithCountValuesFromEvidence(evidence);
    return (React.createElement(RankedEvidenceBarsInternal, { values: values, v3Counts: v3Counts, ctPhasesCounts: ctPhasesCounts, shouldShowLocked: shouldShowLocked }));
};
/** A single horizontal bar for displaying a rank of input values */
const RankedEvidenceStack = ({ evidence }) => {
    const values = getEvidenceCountValues(evidence);
    const isEmpty = values.every(({ count }) => !count);
    const bgColors = isEmpty ? EMPTY_BACKGROUND_COLORS : BACKGROUND_COLORS;
    return (React.createElement(RankedStack, { values: values.map((v, i) => (Object.assign(Object.assign({}, v), { label: (React.createElement(Typography, { variant: TypographyVariant.DetailsBold, color: COLORS.black }, v.label)), backgroundColor: bgColors[i % bgColors.length], foregroundColor: FONT_COLORS[i % FONT_COLORS.length] }))) }));
};
export { RankedEvidenceBars, RankedEvidenceStack };
