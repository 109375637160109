import { Block, CardSurface, Spread, Spacer, TabList, Tab, TabPanel, Stack, FilterType, } from '@humanfirst/elektron';
import React, { useCallback, useContext, useMemo } from 'react';
import { Else, If, Then } from 'react-if';
import { StudyResource, useElektraApi } from '@humanfirst/use-elektra-api';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { ScopedEvidenceTable } from 'src/components/ScopedEvidenceTable';
import { ScopedToolTable } from 'src/components/ScopedToolTable';
import { useHasAllEvidence } from 'src/hooks/evidencePermission';
import { deserializeQuery } from 'src/utils/query';
import { ScopedStudyTable } from 'src/components/tables/StudyTable';
import { ConnectContext } from 'src/contexts/ConnectContext';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { useClearSearchStateUrl } from 'src/components/tables/hooks/use-search-state-url';
import { WrappedScopeTable } from './components/WrappedScopeTable';
import { TitleDescription } from './components/TitleDescription';
const useHasStudies = (teamId) => {
    const filter = useMemo(() => {
        if (!teamId) {
            return [];
        }
        return [['teamId', '$eq', teamId]];
    }, [teamId]);
    const { data: studies } = useElektraApi(StudyResource.list({
        filter,
    }), { enabled: !!teamId });
    return !!teamId && !!studies && studies.length > 0;
};
const getTechScope = (ids, view) => {
    if (!ids.length) {
        return [];
    }
    switch (view) {
        case 0:
            return [
                {
                    fieldId: 'airtableId',
                    filterType: FilterType.INCLUDES_ANY,
                    filterValue: ids,
                },
            ];
        case 1:
        case 2:
            return [
                {
                    fieldId: 'toolIds',
                    filterType: FilterType.INCLUDES_ANY,
                    filterValue: ids,
                },
            ];
        default:
            return [];
    }
};
const ScopedSearch = ({ data: workspace }) => {
    var _a, _b;
    const hasAllEvidence = useHasAllEvidence();
    const hasConnectStudiesAndDHTs = useHasPermission('connect', 'read', 'all');
    const [searchView, setSearchView] = useQueryParam('searchView', withDefault(NumberParam, 0));
    const hasStudies = useHasStudies((_a = workspace.team) === null || _a === void 0 ? void 0 : _a.id);
    const hasAtlasConnectStudies = hasConnectStudiesAndDHTs && hasStudies;
    const { currentStudyTechIds, currentInstrumentIds } = useContext(ConnectContext);
    const workspaceTeamId = (_b = workspace.team) === null || _b === void 0 ? void 0 : _b.id;
    const instrumentOrStudyIds = useMemo(() => [...(currentInstrumentIds !== null && currentInstrumentIds !== void 0 ? currentInstrumentIds : []), ...(currentStudyTechIds !== null && currentStudyTechIds !== void 0 ? currentStudyTechIds : [])], [currentInstrumentIds, currentStudyTechIds]);
    const scopes = useMemo(() => {
        var _a, _b;
        const techConnectFilters = getTechScope(instrumentOrStudyIds, searchView);
        const connectFilters = [...techConnectFilters];
        const workspaceScopeFlters = (_b = deserializeQuery((_a = workspace.scope) !== null && _a !== void 0 ? _a : '').filters) !== null && _b !== void 0 ? _b : [];
        return [...workspaceScopeFlters, ...connectFilters];
    }, [searchView, instrumentOrStudyIds, workspace.scope]);
    const clearSearchStateUrl = useClearSearchStateUrl();
    const onTabChange = useCallback((v) => {
        clearSearchStateUrl();
        setSearchView(v);
    }, [clearSearchStateUrl, setSearchView]);
    const studiesScopes = useMemo(() => {
        return [
            ...scopes,
            {
                fieldId: 'teamId',
                filterType: FilterType.EQUALS,
                filterValue: workspaceTeamId,
            },
        ];
    }, [scopes, workspaceTeamId]);
    return (React.createElement(CardSurface, null,
        React.createElement(Block, { padding: "32px" },
            React.createElement(If, { condition: hasAllEvidence || hasAtlasConnectStudies },
                React.createElement(Then, null,
                    React.createElement(Spread, { alignItems: "center" },
                        React.createElement(Block, null,
                            React.createElement(TitleDescription, { scope: scopes, viewType: "instruments and evidence" })),
                        React.createElement(TabList, { variant: "toggle", active: searchView, onChange: onTabChange },
                            React.createElement(Tab, null, "By Technology"),
                            hasAllEvidence ? React.createElement(Tab, null, "By Evidence") : null,
                            hasAtlasConnectStudies ? React.createElement(Tab, null, "By My Studies") : null)),
                    React.createElement(Spacer, { size: "32px" }),
                    React.createElement(TabPanel, { index: 0, active: searchView },
                        React.createElement(WrappedScopeTable, { TableComponent: ScopedToolTable, workspaceId: workspace.id, scope: scopes })),
                    hasAllEvidence ? (React.createElement(TabPanel, { index: 1, active: searchView },
                        React.createElement(WrappedScopeTable, { workspaceId: workspace.id, scope: scopes, TableComponent: ScopedEvidenceTable }))) : null,
                    hasAtlasConnectStudies ? (React.createElement(TabPanel, { index: 2, active: searchView },
                        React.createElement(WrappedScopeTable, { workspaceId: workspace.id, scope: studiesScopes, TableComponent: ScopedStudyTable }))) : null),
                React.createElement(Else, null,
                    React.createElement(Stack, { gap: "16px" },
                        React.createElement(TitleDescription, { scope: scopes, viewType: "instruments" }),
                        React.createElement(WrappedScopeTable, { TableComponent: ScopedToolTable, scope: scopes, workspaceId: workspace.id })))))));
};
export { ScopedSearch };
