import { __awaiter } from "tslib";
import React, { useEffect, useMemo, useState } from 'react';
import { Spinner, Stack, } from '@humanfirst/elektron';
import { EvidenceResource, ToolResource } from '@humanfirst/use-elektra-api';
import { TechnologySearchType } from '@humanfirst/api-models';
import { When } from 'react-if';
import { MultiBarChart, SingleBarChart } from 'src/components/BarChart';
import { serializeFilters } from 'src/hooks/search/filters';
import { useSearchClient } from 'src/hooks/search';
import { deserializeQuery } from 'src/utils/query';
import { Indicies } from 'src/constants/search';
import { useLogger } from 'src/hooks/logger';
import { ExportCSVButton } from 'src/components/ExportCSVButton';
const CHART_TYPE_DEVICES_PATTERN = /^(devices?-?chart)$/i;
const CHART_TYPE_EVIDENCE_PATTERN = /^(evidence-?chart)$/i;
const CHART_QUERY_LABEL_PATTERN = /^(chartLabel|chart-label)$/i;
function getFacetParameter(input) {
    if (typeof input.facet === 'string') {
        return input.facet;
    }
    if (Array.isArray(input.facet)) {
        return input.facet[0];
    }
    throw new Error('missing facet');
}
function translateParameters(input) {
    const params = {
        facet: getFacetParameter(input),
        enableExport: false,
    };
    if (typeof input.export === 'string' &&
        input.export.toLowerCase() === 'true') {
        params.enableExport = true;
    }
    if (typeof input.limit === 'string') {
        params.limit = parseInt(input.limit);
    }
    return params;
}
function getTokenResourceForLanguage(language) {
    if (CHART_TYPE_EVIDENCE_PATTERN.test(language)) {
        return EvidenceResource.getSearchToken();
    }
    if (CHART_TYPE_DEVICES_PATTERN.test(language)) {
        return ToolResource.getSearchToken(TechnologySearchType.AllAttributes);
    }
    return null;
}
function getIndexNameForLanguage(language) {
    if (CHART_TYPE_EVIDENCE_PATTERN.test(language)) {
        return Indicies.Evidence;
    }
    if (CHART_TYPE_DEVICES_PATTERN.test(language)) {
        return Indicies.Tools;
    }
    throw new Error('invalid chart type');
}
const useChartFacetData = (chartType, chartQueries, facet, limit) => {
    const logger = useLogger(useChartFacetData.name);
    const [isFacetDataLoading, setIsFacetDataLoading] = useState(true);
    const [facetData, setFacetData] = useState([]);
    const { searchClient, isLoading: isSearchClientLoading, isReady: isSearchClientReady, } = useSearchClient(getTokenResourceForLanguage(chartType));
    useEffect(() => {
        let isMounted = true;
        // If no data is requested then we shouldn't do anything?
        if (limit === 0) {
            setFacetData([]);
            setIsFacetDataLoading(false);
            return;
        }
        if (!isSearchClientReady) {
            return;
        }
        setIsFacetDataLoading(true);
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const indexName = getIndexNameForLanguage(chartType);
            // First, get the possible facet attributes
            const facetResults = yield searchClient.searchForFacetValues(chartQueries.map(({ filters, query }) => ({
                indexName,
                params: {
                    query,
                    maxFacetHits: limit,
                    filters: serializeFilters(filters),
                    facetName: facet,
                    sortFacetValuesBy: 'count',
                    facetQuery: '',
                },
            })));
            if (!isMounted) {
                logger.warn('unmounted before data completely loaded');
                return;
            }
            setFacetData(facetResults.flatMap(({ facetHits }, index) => facetHits.map((hit) => ({
                label: chartQueries[index].label,
                value: hit.value,
                count: hit.count,
            }))));
            setIsFacetDataLoading(false);
        }))().catch((e) => logger.error(e));
        return () => {
            isMounted = false;
        };
    }, [
        chartType,
        limit,
        logger,
        isSearchClientReady,
        searchClient,
        facet,
        chartQueries,
    ]);
    return useMemo(() => ({
        isLoading: isFacetDataLoading || isSearchClientLoading,
        data: facetData,
    }), [isFacetDataLoading, isSearchClientLoading, facetData]);
};
export const MarkdownCodeExtensionChartFacet = ({ language, code, parameters, }) => {
    const lines = useMemo(() => code
        .trim()
        .split('\n')
        .map((line) => line.trim()), [code]);
    const chartQueries = useMemo(() => {
        return lines.map((line) => {
            line = line.replace(/^(viw[A-Za-z0-9]+)/, 'views:$1');
            const { search = '', filters = [] } = deserializeQuery(line);
            const chartLabel = filters.find(({ fieldId }) => CHART_QUERY_LABEL_PATTERN.test(fieldId));
            return {
                query: search,
                filters: filters.filter(({ fieldId }) => !CHART_QUERY_LABEL_PATTERN.test(fieldId)),
                label: (chartLabel === null || chartLabel === void 0 ? void 0 : chartLabel.filterValue)
                    ? String(chartLabel.filterValue)
                    : undefined,
            };
        });
    }, [lines]);
    const { facet, limit, enableExport } = useMemo(() => {
        try {
            return translateParameters(parameters);
        }
        catch (e) {
            return {
                facet: '',
                limit: 0,
                enableExport: false,
            };
        }
    }, [parameters]);
    const { data: facetData, isLoading } = useChartFacetData(language, chartQueries, facet, limit);
    const data = useMemo(() => facetData.map(({ count, value, label }) => ({
        x: value !== null && value !== void 0 ? value : 'Unknown',
        item: label !== null && label !== void 0 ? label : '',
        y: count,
    })), [facetData]);
    const exportData = useMemo(() => facetData.map((datum) => {
        var _a, _b;
        return ({
            count: datum.count.toString(),
            value: (_a = datum.value) !== null && _a !== void 0 ? _a : '',
            label: (_b = datum.label) !== null && _b !== void 0 ? _b : '',
        });
    }), [facetData]);
    if (isLoading) {
        return React.createElement(Spinner, null);
    }
    const chartLabels = Array.from(new Set(chartQueries.map(({ label }) => label !== null && label !== void 0 ? label : '')));
    if (chartLabels.length > 1) {
        return (React.createElement(Stack, { gap: "0px" },
            React.createElement(When, { condition: enableExport },
                React.createElement(Stack, { direction: "horizontal", justifyContent: "end" },
                    React.createElement(ExportCSVButton, { payload: exportData, variant: "minimal" }, "Export as CSV"))),
            React.createElement(MultiBarChart, { data: data, variant: "grouped" })));
    }
    else {
        return (React.createElement(Stack, null,
            React.createElement(Stack, { direction: "horizontal", justifyContent: "end" },
                React.createElement(ExportCSVButton, { payload: exportData, variant: "minimal" }, "Export as CSV")),
            React.createElement(SingleBarChart, { data: data, variant: "functional" })));
    }
};
