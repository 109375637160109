import { Link, COLORS, Spread, Stack, Tag, Typography, TypographyVariant, Button, Spacer, Block, Card, Divider, } from '@humanfirst/elektron';
import { EvidenceResource, ToolResource, useElektraApi, } from '@humanfirst/use-elektra-api';
import React, { useMemo, useState } from 'react';
import { When } from 'react-if';
import { BetaTag } from 'src/components/Badges';
import { IfToggle } from 'src/components/IfToggle';
import { LoadingState } from 'src/components/LoadingState';
import { PATHS } from 'src/config/path';
import { useSearchWithFacets, } from 'src/hooks/search';
import { generatePath } from 'src/utils/path';
import { deserializeQuery } from 'src/utils/query';
import { scopeToFilterValues } from 'src/utils/workspaceScope';
const TechTypeInsights = ({ insights }) => {
    var _a, _b, _c, _d;
    const topTechType = useMemo(() => {
        var _a, _b, _c;
        const typeFacet = (_c = (_b = (_a = insights.facets.filter((x) => x.field === 'technologyType')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.facets) !== null && _c !== void 0 ? _c : [];
        return {
            software: typeFacet.find((x) => x.facet === 'Software-Based Component'),
            physical: typeFacet.find((x) => x.facet === 'Physical Sensor'),
        };
    }, [insights.facets]);
    return (React.createElement(Stack, null,
        React.createElement(Block, null,
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Details }, "Technology Type"),
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Label }, "by Technology Count")),
        React.createElement(Stack, null,
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                React.createElement(Tag, { closeable: false },
                    React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                        React.createElement(Typography, { singleLine: true, variant: TypographyVariant.Details }, "Physical Sensor"))),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, (_b = (_a = topTechType.physical) === null || _a === void 0 ? void 0 : _a.count) === null || _b === void 0 ? void 0 : _b.toLocaleString())),
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                React.createElement(Tag, { closeable: false },
                    React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                        React.createElement(Typography, { singleLine: true, variant: TypographyVariant.Details }, "Software Based Component"))),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, (_d = (_c = topTechType.software) === null || _c === void 0 ? void 0 : _c.count) === null || _d === void 0 ? void 0 : _d.toLocaleString())))));
};
const TechVendorInsights = ({ insights }) => {
    const topVendors = useMemo(() => {
        var _a, _b, _c;
        const makerFacet = (_c = (_b = (_a = insights.facets.filter((x) => x.field === 'maker')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.facets) !== null && _c !== void 0 ? _c : [];
        return makerFacet.slice(0, 3);
    }, [insights.facets]);
    return (React.createElement(Stack, null,
        React.createElement(Block, null,
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Details }, "Top Vendors"),
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Label }, "by Technology Count")),
        React.createElement(Stack, null, topVendors.map((x) => {
            return (React.createElement(Stack, { key: x.count, direction: 'horizontal', alignItems: 'center' },
                React.createElement(Tag, { closeable: false },
                    React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                        React.createElement(Typography, { singleLine: true, variant: TypographyVariant.Details }, x.facet))),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, x.count)));
        }))));
};
const TechnologyInsights = ({ scopes }) => {
    const techInsights = useSearchWithFacets({
        searchType: 'Technology',
        filters: scopes,
    });
    const { data: totalTech, isLoading: isTechLoading } = useElektraApi(ToolResource.getStatistics());
    if (isTechLoading || techInsights.isLoading) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(Stack, null,
        React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Technologies"),
        React.createElement(Stack, { gap: '48px', direction: 'horizontal' },
            React.createElement(Block, null,
                React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, "Matching Research Criteria"),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, techInsights.hitCount.toLocaleString()),
                React.createElement(Spacer, null),
                React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, "Total in Atlas"),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, totalTech === null || totalTech === void 0 ? void 0 : totalTech.count.toLocaleString())),
            React.createElement(TechVendorInsights, { insights: techInsights }),
            React.createElement(TechTypeInsights, { insights: techInsights }))));
};
const V3Insights = () => {
    return (React.createElement(Stack, null,
        React.createElement(Block, null,
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Details }, "V3 Types"),
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Label }, "by Evidence Count")),
        React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
            React.createElement(Block, { borderRadius: "50%", backgroundColor: COLORS.blue3, width: '100px', height: '100px' }),
            React.createElement(Block, { backgroundColor: COLORS.gray3, width: '90px', height: '75px' }))));
};
const CTPhaseInsights = () => {
    return (React.createElement(Stack, null,
        React.createElement(Block, null,
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Details }, "Clinical Trial Phase"),
            React.createElement(Typography, { color: COLORS.gray1, variant: TypographyVariant.Label }, "by Evidence Count")),
        React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
            React.createElement(Block, { borderRadius: "50%", backgroundColor: COLORS.blue3, width: '100px', height: '100px' }),
            React.createElement(Block, { backgroundColor: COLORS.gray3, width: '90px', height: '75px' }))));
};
const EvidenceInsights = ({ scopes }) => {
    const evidenceInsights = useSearchWithFacets({
        searchType: 'Evidence',
        filters: scopes,
    });
    const { data: totalEvidence, isLoading: isEvidenceLoading } = useElektraApi(EvidenceResource.getStatistics());
    if (isEvidenceLoading) {
        return React.createElement(LoadingState, null);
    }
    return (React.createElement(Stack, null,
        React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Evidence"),
        React.createElement(Stack, { gap: '48px', direction: 'horizontal' },
            React.createElement(Block, null,
                React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, "Matching Research Criteria"),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, evidenceInsights.hitCount.toLocaleString()),
                React.createElement(Spacer, null),
                React.createElement(Typography, { variant: TypographyVariant.Details, color: COLORS.gray1 }, "Total in Atlas"),
                React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, totalEvidence === null || totalEvidence === void 0 ? void 0 : totalEvidence.count.toLocaleString())),
            React.createElement(V3Insights, null),
            React.createElement(CTPhaseInsights, null))));
};
const ResearchCriteriaInsights = ({ scopes }) => {
    return (React.createElement(Card, { variant: "tertiary" },
        React.createElement(Stack, { direction: 'horizontal' },
            React.createElement(Typography, { variant: TypographyVariant.BodyBold }, "Overview of Data by Research Criteria"),
            React.createElement(BetaTag, null)),
        React.createElement(Spacer, { size: '12px' }),
        React.createElement(Stack, { gap: '48px' },
            React.createElement(Stack, { gap: '24px' },
                React.createElement(TechnologyInsights, { scopes: scopes !== null && scopes !== void 0 ? scopes : [] }),
                React.createElement(Divider, null),
                React.createElement(EvidenceInsights, { scopes: scopes !== null && scopes !== void 0 ? scopes : [] })))));
};
const CurrentResearchCriteria = ({ workspace, }) => {
    var _a;
    const scopeValues = scopeToFilterValues((_a = workspace.scope) !== null && _a !== void 0 ? _a : '');
    const editUrl = generatePath(PATHS.projects.edit, {
        projectId: workspace.id,
    });
    return (React.createElement(Spread, { gap: "24px", direction: "horizontal", alignItems: "start" }, Object.entries(scopeValues).map(([key, values]) => (React.createElement(Stack, { key: key, direction: "vertical", gap: "16px", alignItems: "start", flex: "1 1", width: '100%' },
        React.createElement(Typography, { variant: TypographyVariant.Body, color: COLORS.gray1, singleLine: true }, key),
        React.createElement(When, { condition: values.length === 0 },
            React.createElement(Link, { href: editUrl }, "Set Criteria")),
        values.map((value) => (React.createElement(Tag, { key: value, variant: "outlined", color: "transparent", closeable: false, kind: "neutral", maxWidth: "auto" },
            React.createElement(Typography, { singleLine: true, variant: TypographyVariant.Body, color: COLORS.black }, value)))))))));
};
export const ProjectResearchCriteria = ({ workspace, }) => {
    const workspaceScopeFlters = useMemo(() => {
        var _a, _b;
        return (_b = deserializeQuery((_a = workspace.scope) !== null && _a !== void 0 ? _a : '').filters) !== null && _b !== void 0 ? _b : [];
    }, [workspace.scope]);
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { gap: "16px", alignSelf: 'start' },
            React.createElement(Stack, { direction: 'horizontal', alignItems: 'center' },
                React.createElement(Typography, { variant: TypographyVariant.Heading3Bold, marginBottom: '0px' }, "Research Criteria"),
                React.createElement(IfToggle, { name: "project-research-insights" },
                    React.createElement(Button, { variant: "minimal", disabled: !workspace.scope, onClick: () => setIsOpen(!isOpen) },
                        isOpen ? 'Hide' : 'View',
                        " Data Overview",
                        React.createElement(Spacer, { size: "4px" }),
                        React.createElement(BetaTag, null)))),
            React.createElement(CurrentResearchCriteria, { workspace: workspace })),
        React.createElement(IfToggle, { name: "project-research-insights" },
            React.createElement(When, { condition: isOpen },
                React.createElement(Block, { maxWidth: '800px' },
                    React.createElement(Spacer, null),
                    React.createElement(ResearchCriteriaInsights, { scopes: workspaceScopeFlters }))))));
};
