import { COLORS, Typography, TypographyVariant, } from '@humanfirst/elektron';
import React from 'react';
const TitleDescription = ({ scope, viewType, }) => (React.createElement(React.Fragment, null,
    React.createElement(Typography, { marginBottom: "16px", variant: TypographyVariant.Heading2 }, "Search"),
    React.createElement(Typography, { variant: TypographyVariant.Body, color: COLORS.gray1 },
        "Explore ",
        viewType,
        (scope === null || scope === void 0 ? void 0 : scope.length)
            ? ', pre-filtered to your project research criteria.'
            : ' from Atlas.')));
export { TitleDescription };
