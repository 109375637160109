import { __awaiter } from "tslib";
import { useEffect, useState } from 'react';
import { useLogger } from '../logger';
function useRawSearch({ searchClient, indexName, options: params, enabled = true, }) {
    const logger = useLogger(useRawSearch.name);
    const [response, setResponse] = useState({
        isLoading: enabled, // Disabled queries should not be loading
        isError: false,
        error: undefined,
        response: undefined,
    });
    useEffect(() => {
        if (!enabled || !searchClient) {
            setResponse((r) => (r.isLoading ? Object.assign(Object.assign({}, r), { isLoading: false }) : r));
            return;
        }
        let isMounted = true;
        setResponse((r) => (!r.isLoading ? Object.assign(Object.assign({}, r), { isLoading: true }) : r));
        (() => __awaiter(this, void 0, void 0, function* () {
            try {
                const { results } = yield searchClient.search([
                    {
                        indexName,
                        params,
                    },
                ]);
                if (!isMounted) {
                    return;
                }
                setResponse({
                    isLoading: false,
                    isError: false,
                    error: undefined,
                    response: results[0],
                });
            }
            catch (e) {
                if (!isMounted) {
                    return;
                }
                setResponse((response) => (Object.assign(Object.assign({}, response), { isLoading: false, isError: true, error: e })));
            }
        }))().catch((e) => logger.error(e));
        return () => {
            isMounted = false;
        };
    }, [enabled, indexName, searchClient, params, logger]);
    return response;
}
export { useRawSearch };
