import React, { useMemo } from 'react';
import { Stack } from '@humanfirst/elektron';
import { When } from 'react-if';
import { SingleBarChart } from 'src/components/BarChart';
import { ExportButton } from './ExportButton';
export const MarkdownCodeExtensionBarChart = ({ itemKey, valueKey, header, records, enableExport = false }) => {
    const itemColumn = typeof itemKey === 'string' ? header.indexOf(itemKey) : 0;
    const valueColumn = typeof valueKey === 'string' ? header.indexOf(valueKey) : itemColumn + 1;
    const data = useMemo(() => records.map((row) => ({
        x: row[itemColumn],
        y: parseInt(row[valueColumn]),
    })), [records, itemColumn, valueColumn]);
    return (React.createElement(Stack, { gap: "0px" },
        React.createElement(When, { condition: enableExport },
            React.createElement(Stack, { direction: "horizontal", justifyContent: "end" },
                React.createElement(ExportButton, { header: header, records: records }))),
        React.createElement(SingleBarChart, { data: data, variant: "functional" })));
};
