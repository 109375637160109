import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Block, Stack } from '@humanfirst/elektron';
import { useNavigate } from 'react-router';
import { generatePath } from 'src/utils/path';
import { PATHS } from 'src/config/path';
import { useAnalyticsEvent } from 'src/hooks/analytics';
import { Indicies } from 'src/constants/search';
import { useDebouncedCallback } from 'src/hooks/debounce';
import { useSearchOmni } from 'src/hooks/search';
import { usePersistentState } from 'src/hooks/persistence';
import { RECENT_SEARCHES } from 'src/constants/persistentKeys';
import { OmniSearchSelect } from './OmniSearchSelect';
import { OmnisearchOptionNode } from './OmniSearchOption';
import { OmnisearchEmptyState } from './OmnisearchEmptyState';
const OMNISEARCH_SELECT_EVENT = 'omnisearch-select';
const OMNISEARCH_SEARCH_EVENT = 'omnisearch-search';
const OMNISEARCH_KINDS = [
    'component',
    'vendor',
    'measure',
    'medicalCondition',
    'searchAll',
];
const TypeToLinkGetter = {
    component: (id) => generatePath(PATHS.atlas.technologies.detail, { id }),
    medicalCondition: (id) => generatePath(PATHS.atlas.medicalConditions.detail, { id }),
    measure: (id) => generatePath(PATHS.atlas.measures.detail, { id }),
    vendor: (id) => generatePath(PATHS.atlas.vendors.detail, { id }),
    // TODO: Remove this after toggle.
    model: (id) => generatePath(PATHS.atlas.technologies.detail, { id }),
    searchAll: (id, query) => {
        switch (id) {
            case 'technology':
                return (generatePath(PATHS.atlas.technologies.list) +
                    `?search=${encodeURIComponent(query)}`);
            case 'vendors':
                return (generatePath(PATHS.atlas.vendors.list) +
                    `?search=${encodeURIComponent(query)}`);
            case 'medical conditions':
                return (generatePath(PATHS.atlas.medicalConditions.list) +
                    `?search=${encodeURIComponent(query)}`);
            case 'measures':
                return (generatePath(PATHS.atlas.measures.list) +
                    `?search=${encodeURIComponent(query)}`);
        }
        return generatePath(PATHS.atlas.index);
    },
};
const isOmniSearchOption = (option) => {
    return (!!option &&
        typeof option === 'object' &&
        !!option.id &&
        !!option.type);
};
const useRecentSearches = () => {
    const [recentSearches, setRecentSearches] = usePersistentState(RECENT_SEARCHES, { defaultValue: [] });
    const clearRecentSearches = useCallback(() => {
        setRecentSearches([]);
    }, [setRecentSearches]);
    const addRecentSearch = useCallback((search) => {
        setRecentSearches((previous) => {
            return [search, ...previous].slice(0, 5);
        });
    }, [setRecentSearches]);
    return [recentSearches, addRecentSearch, clearRecentSearches];
};
const DEFAULT_QUERY = { query: '' };
const Omnisearch = ({ initialState = DEFAULT_QUERY, }) => {
    var _a;
    const [query, setQuery] = useState((_a = initialState.query) !== null && _a !== void 0 ? _a : '');
    const controlRef = useRef(null);
    const [recentSearches, addRecentSearch, clearRecentSearches] = useRecentSearches();
    const navigate = useNavigate();
    const track = useAnalyticsEvent();
    const trackSearch = useCallback(({ resultCount, searchQuery, }) => {
        track(OMNISEARCH_SEARCH_EVENT, {
            query: searchQuery,
            index: Indicies.OmniSearch,
            resultCount,
        });
    }, [track]);
    const { isLoading, options } = useSearchOmni({
        query,
        enabled: query.length > 0,
        kinds: OMNISEARCH_KINDS,
    });
    // Clear input value and options on blur and item select
    const clearInputState = useCallback(() => {
        setQuery('');
    }, [setQuery]);
    // If the debonced hook is unmounted, go ahead and call it.
    const flushOnUnmount = true;
    // Wait to track one second after results are back?
    const debouncedTrack = useDebouncedCallback(trackSearch, 1000, flushOnUnmount);
    // Track the search and number of returned results.
    useEffect(() => {
        var _a;
        if (query && !isLoading) {
            debouncedTrack({
                searchQuery: query,
                resultCount: (_a = options === null || options === void 0 ? void 0 : options.length) !== null && _a !== void 0 ? _a : 0,
            });
        }
    }, [isLoading, query, options, debouncedTrack]);
    const onChange = useCallback(({ option }) => {
        if (isOmniSearchOption(option)) {
            const getPathFunction = TypeToLinkGetter[option.type];
            // Track the selection
            track(OMNISEARCH_SELECT_EVENT, {
                selection: option.query,
                query,
                index: Indicies.OmniSearch,
                type: option.type,
            });
            addRecentSearch(query);
            navigate(getPathFunction(option.id, option.query));
            clearInputState();
        }
        // Don't set a value in the select. Just navigate.
    }, [navigate, track, query, clearInputState, addRecentSearch]);
    return (React.createElement(Stack, { direction: "horizontal", alignItems: 'center' },
        React.createElement(Block, { flex: "1" },
            React.createElement(OmniSearchSelect, { placeholder: "Search the entire Atlas catalog", labelKey: "query", onChange: onChange, isLoading: isLoading, onBlur: clearInputState, controlRef: controlRef, options: query ? options : [], getOptionLabel: ({ option }) => (React.createElement(OmnisearchOptionNode, { option: option })), noResultsMsg: React.createElement(OmnisearchEmptyState, { hasQuery: !!query, isLoading: isLoading, recentSearches: recentSearches, clearRecentSearches: clearRecentSearches, setQuery: (q) => {
                        var _a;
                        (_a = controlRef.current) === null || _a === void 0 ? void 0 : _a.setInputValue(q);
                        setQuery(q);
                    } }), valueKey: "query", onInputChange: (e) => {
                    setQuery(e.currentTarget.value);
                } }))));
};
export { Omnisearch };
