import { COLORS, PRIMARY_FONT_FAMILY } from '@humanfirst/elektron';
import { VictoryTheme } from 'victory';
/** Shared label styles */
const sharedLabelStyles = {
    fontFamily: PRIMARY_FONT_FAMILY,
    fontSize: 11,
    fill: COLORS.gray1,
    fontWeight: 600,
};
/** Shared tick styles. */
const sharedTickStyles = {
    size: 10,
    stroke: COLORS.gray3,
    strokeWidth: 0.5,
};
/** SHared axis styles */
const sharedAxisStyles = {
    strokeWidth: 1.5,
    stroke: COLORS.gray3,
};
const legendPropsAndStyles = {
    /** Vertical space between legend items */
    gutter: 16,
    /** Space between legend label and color indicator */
    symbolSpacer: 6,
    borderPadding: 0,
    padding: 0,
    orientation: 'horizontal',
    style: {
        data: {
            /** How the color should show up. 'circle' | 'square' | 'star' */
            type: 'square',
        },
        labels: sharedLabelStyles,
    },
};
const GRAPH_THEME = Object.assign(Object.assign({}, VictoryTheme.grayscale), { tooltip: {
        style: sharedLabelStyles,
        flyoutStyle: {
            stroke: COLORS.gray3,
            filter: 'drop-shadow(3px 5px 2px hsla(0, 0%, 0%, 0.16))',
            backgroundColor: 'white',
            fill: COLORS.white,
            border: 'solid blue',
        },
        centerOffset: { x: 16 },
        cornerRadius: 4,
        flyoutPadding: 10,
    }, 
    /** A bar is the bar that represents a piece of data */
    bar: {
        cornerRadius: { topLeft: 4, topRight: 4 },
        style: {
            data: {
                stroke: 'transparent',
            },
        },
    }, 
    /** Dependent axis is the y-axis in our case. So the count of evidence. */
    dependentAxis: {
        style: {
            axis: sharedAxisStyles,
            /** A grid represents the lines in a graph. For the dependent axis we are referring to the horizontal lines. */
            grid: {
                strokeWidth: 0.5,
                stroke: COLORS.gray3,
            },
            /** Tick labels are the pieces of data being represented on an axis. (measure or count) */
            tickLabels: Object.assign(Object.assign({}, sharedLabelStyles), { padding: 8 }),
            ticks: sharedTickStyles,
        },
    }, 
    /** Independent axis is the x-axis in our case. So the evidence evidence being counted */
    independentAxis: {
        style: {
            axis: sharedAxisStyles,
            /** We want to omit the grid for the x axis. AKA remove the vertical lines */
            grid: {
                stroke: 'transparent',
            },
            /** Tick labels are the pieces of data being represented on an axis. (measure or count) */
            tickLabels: Object.assign(Object.assign({}, sharedLabelStyles), { padding: 8 }),
            /** Ticks are the little lines that connect a tick label to the graph */
            ticks: sharedTickStyles,
        },
    }, legend: legendPropsAndStyles });
export { GRAPH_THEME };
