import { Stack, Typography, TypographyVariant, Tag, Link, } from '@humanfirst/elektron';
import React from 'react';
import { generatePath } from 'react-router';
import { AnchoredTooltip } from 'src/components/AnchoredTooltip';
import { PATHS } from 'src/config/path';
const APPLIED_SCOPE_MAPPING = {
    therapeuticAreas: 'Therapeutic Area',
    medicalConditions: 'Medical Condition',
    // This is a temporary fix to support previous projects that have selected conceptsOfInterest scopes
    conceptsOfInterest: 'Concept of Interest',
    allMeasuresFlat: 'Concept of Interest',
};
const AppliedResearchCriteria = ({ scope, workspaceId }) => {
    if (!(scope === null || scope === void 0 ? void 0 : scope.length)) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { direction: "horizontal", marginBottom: "16px" },
            React.createElement(Typography, { variant: TypographyVariant.DetailsBold }, "Applied Research Criteria"),
            React.createElement(AnchoredTooltip, { triggerType: "hover" },
                React.createElement(Typography, { marginBottom: "16px" },
                    "The following research criteria have been applied to your search results. To make changes, you may",
                    ' ',
                    React.createElement(Link, { href: generatePath(PATHS.projects.edit, {
                            projectId: workspaceId,
                        }) }, "Edit"),
                    ' ',
                    "this project."))),
        React.createElement(Stack, { direction: "horizontal", gap: "8px", flexWrap: true, marginBottom: "24px" }, scope
            .flatMap((x) => {
            const label = APPLIED_SCOPE_MAPPING[x.fieldId];
            if (!label) {
                return [];
            }
            return Array.isArray(x.filterValue)
                ? x.filterValue.map((y) => ({
                    label: label,
                    value: String(y),
                }))
                : [{ label: label, value: String(x.filterValue) }];
        })
            .map((filter) => (React.createElement(Tag, { key: filter.value, closeable: false, variant: "solid" },
            React.createElement(Typography, { variant: TypographyVariant.Details },
                filter.label,
                ": ",
                filter.value)))))));
};
export { AppliedResearchCriteria };
