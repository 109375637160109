import React, { useMemo } from 'react';
import { VictoryLegend, VictoryBar, VictoryGroup, VictoryChart, VictoryTooltip, VictoryAxis, VictoryStack, } from 'victory';
import { COLORS } from '@humanfirst/elektron';
import { GRAPH_THEME } from './theme';
import { GradientDefinitions, GRADIENTS } from './GradientDefinitions';
// Width of the entinre svg.
const CHART_WIDTH = 900;
// Padding of the svg. This allows the space we need for labels.
const CHART_PADDING = 40;
// Domain padding gives the bars space from the axis.
const CHART_DOMAIN_PADDING = { x: 40, y: 0 };
// Define events on our data. For hover, we show the tooltip and update the style
const DATA_EVENTS = [
    {
        target: 'data',
        eventHandlers: {
            onMouseOver: () => {
                return [
                    {
                        target: 'data',
                        mutation: ({ style }) => ({
                            style: Object.assign(Object.assign({}, style), { fillOpacity: 0.5, strokeWidth: 1, stroke: COLORS.primary, cursor: 'pointer' }),
                        }),
                    },
                    {
                        target: 'labels',
                        mutation: () => ({ active: true }),
                    },
                ];
            },
            onMouseOut: () => {
                return [
                    {
                        target: 'data',
                        mutation: () => ({}),
                    },
                    {
                        target: 'labels',
                        mutation: () => ({ active: false }),
                    },
                ];
            },
        },
    },
];
/**
 *
 * The grouped bar chart will display a group of up to 5 bars for every item on the x axis.
 * EX:
 *
 * 10 - |           _            _
 *      |       _  | |          | |
 *      |      | | | |          | |  _
 * 5 -  |   _  | | | |          | | | |
 *      |  | | | | | |       _  | | | |
 *      |  | | | | | |      | | | | | |
 *      |__|_|_|_|_|_|______|_|_|_|_|_|_
 *              |                |
 *            Group 1          Group 2
 */
const MultiBarChart = ({ variant = 'grouped', data, }) => {
    const dataGrouped = useMemo(() => data.flat().reduce((carry, { x, y, item }) => {
        const target = carry.find(({ item: ci }) => item === ci);
        if (!target) {
            carry.push({ item, points: [{ x, y }] });
        }
        else {
            target.points.push({ x, y });
        }
        return carry;
    }, []), [data]);
    const mappedLegendData = useMemo(() => {
        return dataGrouped.map(({ item: name }, i) => ({
            name,
            symbol: { fill: GRADIENTS[i % GRADIENTS.length].color },
        }));
    }, [dataGrouped]);
    const yDomainMax = useMemo(() => {
        if (variant === 'stacked') {
            // When stacked, sum Y by X in groups & max that
            return Math.max(...Object.values(data.flat().reduce((carry, { x, y }) => {
                var _a;
                carry[x] = ((_a = carry[x]) !== null && _a !== void 0 ? _a : 0) + y;
                return carry;
            }, {})));
        }
        // When grouped
        return Math.max(0, ...data.flat().map(({ y }) => y));
    }, [data, variant]);
    const VictoryChartComponent = variant === 'stacked' ? VictoryStack : VictoryGroup;
    const domain = useMemo(() => ({
        y: [0, Math.max(4, yDomainMax)],
    }), [yDomainMax]);
    const categories = useMemo(() => ({ x: data.flat().map(({ x }) => x) }), [data]);
    return (React.createElement(VictoryChart, { width: CHART_WIDTH, padding: CHART_PADDING, domainPadding: CHART_DOMAIN_PADDING, theme: GRAPH_THEME, 
        // 4 is the minimum number for keeping whole numbers on they axis.
        domain: domain },
        React.createElement(VictoryLegend, { data: mappedLegendData }),
        React.createElement(VictoryChartComponent, { offset: 16, categories: categories }, dataGrouped.map(({ item, points }, i) => (React.createElement(VictoryBar, { key: item, style: {
                data: {
                    fill: `url(#${GRADIENTS[i % GRADIENTS.length].id})`,
                    width: 16,
                },
            }, data: points, labels: ({ datum }) => `${item} - ${datum.x}: ${datum.y}`, labelComponent: React.createElement(VictoryTooltip, null), events: DATA_EVENTS })))),
        React.createElement(VictoryAxis, null),
        React.createElement(VictoryAxis, { tickFormat: (y) => Math.round(y), dependentAxis: true }),
        React.createElement(GradientDefinitions, null)));
};
export { MultiBarChart };
