import { Icons, Modal, Stack, Typography, COLORS, TypographyVariant, Spread, Card, Image, Button, Pagination, Block, } from '@humanfirst/elektron';
import React, { useCallback, useState } from 'react';
import { When } from 'react-if';
import NeuroSlide1 from 'src/assets/insights/neuro-slide-1.svg';
import NeuroSlide2 from 'src/assets/insights/neuro-slide-2.svg';
import OncologySlide1 from 'src/assets/insights/oncology-slide-1.svg';
import OncologySlide2 from 'src/assets/insights/oncology-slide-2.svg';
import InsightsPreview from 'src/assets/insights/insights.svg';
import { AtlasMarkdown } from 'src/components/AtlasMarkdown';
import { BetaTag } from 'src/components/Badges';
import { useShowModalBound } from 'src/hooks/modal';
export const TAS_WITH_INSIGHTS = new Set(['Neurology', 'Oncology']);
const getTASpecficInsights = (therapeuticArea) => {
    if (!TAS_WITH_INSIGHTS.has(therapeuticArea)) {
        return;
    }
    if (therapeuticArea === 'Neurology') {
        return `\n\`\`\`image-gallery\n${NeuroSlide1} "Slide 1"\n${NeuroSlide2} "Slide 2"\n\`\`\`\n`;
    }
    if (therapeuticArea === 'Oncology') {
        return `\n\`\`\`image-gallery\n${OncologySlide1} "Slide 1"\n${OncologySlide2} "Slide 2"\n\`\`\`\n`;
    }
};
const InsightsModal = ({ isOpen, closeModal, therapeuticArea, workspace }) => {
    const insightContent = getTASpecficInsights(therapeuticArea);
    const showContactUsModal = useShowModalBound('contact-us', {
        location: `Project Insights: ${workspace.id}`,
        contactType: 'General Contact',
    });
    if (!insightContent) {
        return null;
    }
    return (React.createElement(Modal, { isOpen: isOpen, onClose: closeModal },
        React.createElement(Stack, { direction: 'horizontal' },
            React.createElement(Typography, { variant: TypographyVariant.Heading2Bold },
                therapeuticArea,
                ": Evidence Coverage in Atlas for V3 and Trials"),
            React.createElement(BetaTag, null)),
        React.createElement(Block, { minHeight: '400px' },
            React.createElement(AtlasMarkdown, { content: insightContent })),
        React.createElement(Stack, { direction: 'horizontal', alignItems: 'center', justifyContent: 'flex-end' },
            React.createElement(Typography, null, "Have a specific research question for this TA?"),
            React.createElement(Button, { variant: "secondary", onClick: showContactUsModal, size: "large" }, "Contact our experts"))));
};
const InsightsCard = ({ therapeuticArea, workspace }) => {
    const [isOpen, setIsOpen] = useState(false);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    const openModal = useCallback(() => setIsOpen(true), []);
    return (React.createElement(Stack, null,
        React.createElement(InsightsModal, { closeModal: closeModal, isOpen: isOpen, therapeuticArea: therapeuticArea, workspace: workspace }),
        React.createElement(Image, { src: InsightsPreview, alt: 'coverage-preview', onClick: openModal, style: { cursor: 'pointer' } }),
        React.createElement(Block, null,
            React.createElement(Button, { onClick: openModal, variant: 'minimal', startEnhancer: React.createElement(Icons.Misc.Maximize, { decorative: true, color: COLORS.primary, size: '16px' }) }, "View Coverage Data"))));
};
export const ProjectInsights = ({ workspace, relevantScopes }) => {
    const [slideSet, setSlideSet] = useState(1);
    const tasWithInsights = relevantScopes.filter((x) => TAS_WITH_INSIGHTS.has(x));
    if (!tasWithInsights.length) {
        return null;
    }
    return (React.createElement(Stack, null,
        React.createElement(Card, { backgroundColor: COLORS.blue4, variant: 'tertiary' },
            React.createElement(Stack, null,
                React.createElement(Spread, null,
                    React.createElement(Stack, { direction: 'horizontal' },
                        React.createElement(Typography, { variant: TypographyVariant.Heading3Bold }, "Atlas Evidence Coverage"),
                        React.createElement(BetaTag, null)),
                    React.createElement(When, { condition: tasWithInsights.length > 1 },
                        React.createElement(Pagination, { numPages: tasWithInsights.length, currentPage: slideSet, onNextClick: () => setSlideSet((slide) => slide + 1), onPrevClick: () => setSlideSet((slide) => slide - 1) }))),
                React.createElement(Typography, null,
                    "Understand clinical trial and V3 evidence coverage in",
                    ' ',
                    React.createElement("b", null, tasWithInsights[slideSet - 1]),
                    "."),
                React.createElement(InsightsCard, { workspace: workspace, therapeuticArea: tasWithInsights[slideSet - 1] })))));
};
